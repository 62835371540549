var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('vuexy-logo',{staticClass:"brand-logo"}),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('h3',{staticClass:"mb-2"},[_vm._v(" Tạo tài khoản ATVSLĐ ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label-for":"register-company"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Tên doanh nghiệp "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Tên doanh nghiệp là bắt buộc",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company","name":"register-company","state":errors.length > 0 ? false:null,"placeholder":"Tên doanh nghiệp"},model:{value:(_vm.dataInput.name),callback:function ($$v) {_vm.$set(_vm.dataInput, "name", $$v)},expression:"dataInput.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"basicInput"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Loại hình doanh nghiệp"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Loại hình doanh nghiệp là bắt buộc",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': !!errors.length},attrs:{"reduce":function (data) { return data.id; },"label":"name","placeholder":"Lựa chọn loại hình doanh nghiệp ","options":_vm.dataCombobox || []},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.bussinessTypeId),callback:function ($$v) {_vm.$set(_vm.dataInput, "bussinessTypeId", $$v)},expression:"dataInput.bussinessTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"basicInput"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Ngành nghề kinh doanh chính"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Ngành nghề kinh doanh chính là bắt buộc",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': !!errors.length},attrs:{"reduce":function (data) { return data.id; },"label":"codeName","placeholder":"Lựa chọn Ngành nghề kinh doanh chính ","options":_vm.dataComboboxSector || []},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.bussinessSectorId),callback:function ($$v) {_vm.$set(_vm.dataInput, "bussinessSectorId", $$v)},expression:"dataInput.bussinessSectorId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-taxid"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Mã số thuế "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Mã số thuế là bắt buộc",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-taxid","name":"register-taxid","state":errors.length > 0 ? false:null,"placeholder":"Mã số thuế"},model:{value:(_vm.dataInput.taxNumber),callback:function ($$v) {_vm.$set(_vm.dataInput, "taxNumber", $$v)},expression:"dataInput.taxNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-username"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Tên đăng nhập "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required|special_characters","custom-messages":{required: "Tên đăng nhập là bắt buộc",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":"Tên đăng nhập"},model:{value:(_vm.dataInput.userName),callback:function ($$v) {_vm.$set(_vm.dataInput, "userName", $$v)},expression:"dataInput.userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-email"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Email "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Email là bắt buộc",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.dataInput.email),callback:function ($$v) {_vm.$set(_vm.dataInput, "email", $$v)},expression:"dataInput.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Mật khẩu "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required|special_characters","custom-messages":{required: "Mật khẩu là bắt buộc",}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","autocomplete":"new-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password"},model:{value:(_vm.dataInput.password),callback:function ($$v) {_vm.$set(_vm.dataInput, "password", $$v)},expression:"dataInput.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Tôi đồng ý "),_c('b-link',[_vm._v("các điều khoản.")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":!_vm.status}},[_vm._v(" Đăng ký ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Bạn đã có tài khoản?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Đăng nhập ngay")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }