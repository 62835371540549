<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <vuexy-logo class="brand-logo" />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <h3 class="mb-2">
            Tạo tài khoản ATVSLĐ
          </h3>

          <!-- form -->
          <validation-observer
            ref="registerForm"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- company -->
              <b-form-group
                label-for="register-company"
              >
                <label for="label">Tên doanh nghiệp <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Tên doanh nghiệp là bắt buộc`,}"
                >
                  <b-form-input
                    id="register-company"
                    v-model="dataInput.name"
                    name="register-company"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Tên doanh nghiệp"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="basicInput"
              >
                <label
                  for="label"
                >Loại hình doanh nghiệp<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Loại hình doanh nghiệp là bắt buộc`,}"
                >
                  <v-select
                    v-model="dataInput.bussinessTypeId"
                    :reduce="data => data.id"
                    label="name"
                    :class="{'is-invalid': !!errors.length}"
                    :placeholder="`Lựa chọn loại hình doanh nghiệp `"
                    :options="dataCombobox || []"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="basicInput"
              >
                <label
                  for="label"
                >Ngành nghề kinh doanh chính<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Ngành nghề kinh doanh chính là bắt buộc`,}"
                >
                  <v-select
                    v-model="dataInput.bussinessSectorId"
                    :reduce="data => data.id"
                    label="codeName"
                    :class="{'is-invalid': !!errors.length}"
                    :placeholder="`Lựa chọn Ngành nghề kinh doanh chính `"
                    :options="dataComboboxSector || []"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- TaxId -->
              <b-form-group
                label-for="register-taxid"
              >
                <label for="label">Mã số thuế  <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Mã số thuế là bắt buộc`,}"
                >
                  <b-form-input
                    id="register-taxid"
                    v-model="dataInput.taxNumber"
                    name="register-taxid"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Mã số thuế"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- username -->
              <b-form-group
                label-for="register-username"
              >
                <label for="label">Tên đăng nhập <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|special_characters"
                  :custom-messages="{required: `Tên đăng nhập là bắt buộc`,}"
                >
                  <b-form-input
                    id="register-username"
                    v-model="dataInput.userName"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Tên đăng nhập"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label-for="register-email"
              >
                <label for="label">Email <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Email là bắt buộc`,}"
                >
                  <b-form-input
                    id="register-email"
                    v-model="dataInput.email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
              >
                <label for="label">Mật khẩu <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|special_characters"
                  :custom-messages="{required: `Mật khẩu là bắt buộc`,}"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="dataInput.password"
                      class="form-control-merge"
                      autocomplete="new-password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                >
                  Tôi đồng ý
                  <b-link>các điều khoản.</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="!status"
              >
                Đăng ký
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Bạn đã có tài khoản?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Đăng nhập ngay</span>
            </b-link>
          </p>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, email, characters } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    vSelect,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      dataCombobox: [],
      dataComboboxSector: [],
      dataInput: {
        name: '',
        taxNumber: '',
        userName: '',
        password: '',
        email: '',
        bussinessTypeId: '',
        bussinessSectorId: '',
        domainFrontEnd: `${window.location.origin}/kich-hoat-tai-khoan`,
      },
      status: false,
      sideImg: require('@/assets/images/pages/login-image.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {

    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-image.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  async created() {
    await this.dataComboboxBusiness()
    await this.dataComboboxSectors()
  },
  methods: {
    // gọi API combobox loại hình daonh nghiệp
    async dataComboboxBusiness() {
      const { data } = await axiosApiInstance.get('/Home/get-combobox-bussiness-type')
      this.dataCombobox = data?.data
    },

    // gọi API combobox ngành nghề kinh doanh chính
    async dataComboboxSectors() {
      const { data } = await axiosApiInstance.get('/Home/get-combobox-sector-treeview')
      this.dataComboboxSector = data?.data
      if (data.data) {
        this.dataComboboxSector.forEach(ele => {
          if (ele.level1) {
            ele.codeName = `${ele.level1} - ${ele.name}`
          }
          if (!ele.level1) {
            ele.codeName = `${ele.level2} - ${ele.name}`
          }
          if (!ele.level1 && !ele.level2) {
            ele.codeName = `${ele.level3} - ${ele.name}`
          }
        })
      }
    },

    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$showAllPageLoading()
          const res = axiosApiInstance.post('/Home/registerBussiness', this.dataInput).then(response => {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Tạo tài khoản thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$router.push({ name: 'active-account-status', params: { type: 'kiem-tra-email' } })
          }).catch(e => {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(e.response.data.errors[0], {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
